define("ember-sortable/templates/components/sortable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zY890t+P",
    "block": "[[[18,1,[[30,0]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-sortable/templates/components/sortable-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});