define("ember-table/components/ember-tbody/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#vertical-collection
    items=this.wrappedRows
    containerSelector=this._containerSelector
  
    estimateHeight=this.estimateRowHeight
    key=this.key
    staticHeight=this.staticHeight
    bufferSize=this.bufferSize
    renderAll=this.renderAll
  
    firstReached=this.firstReached
    lastReached=this.lastReached
    firstVisibleChanged=this.firstVisibleChanged
    lastVisibleChanged=this.lastVisibleChanged
    idForFirstItem=this.idForFirstItem
  
    as |rowValue|
  }}
    <EmberTablePrivate::RowWrapper
      @rowValue={{rowValue}}
      @columns={{this.columns}}
      @columnMetaCache={{this.columnMetaCache}}
      @rowMetaCache={{this.rowMetaCache}}
      @canSelect={{this.canSelect}}
      @checkboxSelectionMode={{this.checkboxSelectionMode}}
      @rowSelectionMode={{this.rowSelectionMode}}
      @rowToggleMode={{this.rowToggleMode}}
      @rowsCount={{this.wrappedRows.length}} as |api|
    >
      {{#if (has-block)}}
        {{yield (hash
          rowValue=api.rowValue
          rowMeta=api.rowMeta
          cells=api.cells
          rowSelectionMode=api.rowSelectionMode
          rowToggleMode=api.rowToggleMode
          rowsCount=api.rowsCount
  
          row=(component "ember-tr" api=api)
        )}}
      {{else}}
        <EmberTr @api={{api}} />
      {{/if}}
    </EmberTablePrivate::RowWrapper>
  
  {{else}}
    {{yield to='inverse'}}
  {{/vertical-collection}}
  
  */
  {
    "id": "xaecTcZC",
    "block": "[[[6,[39,0],null,[[\"items\",\"containerSelector\",\"estimateHeight\",\"key\",\"staticHeight\",\"bufferSize\",\"renderAll\",\"firstReached\",\"lastReached\",\"firstVisibleChanged\",\"lastVisibleChanged\",\"idForFirstItem\"],[[30,0,[\"wrappedRows\"]],[30,0,[\"_containerSelector\"]],[30,0,[\"estimateRowHeight\"]],[30,0,[\"key\"]],[30,0,[\"staticHeight\"]],[30,0,[\"bufferSize\"]],[30,0,[\"renderAll\"]],[30,0,[\"firstReached\"]],[30,0,[\"lastReached\"]],[30,0,[\"firstVisibleChanged\"]],[30,0,[\"lastVisibleChanged\"]],[30,0,[\"idForFirstItem\"]]]],[[\"default\",\"else\"],[[[[1,\"  \"],[8,[39,1],null,[[\"@rowValue\",\"@columns\",\"@columnMetaCache\",\"@rowMetaCache\",\"@canSelect\",\"@checkboxSelectionMode\",\"@rowSelectionMode\",\"@rowToggleMode\",\"@rowsCount\"],[[30,1],[30,0,[\"columns\"]],[30,0,[\"columnMetaCache\"]],[30,0,[\"rowMetaCache\"]],[30,0,[\"canSelect\"]],[30,0,[\"checkboxSelectionMode\"]],[30,0,[\"rowSelectionMode\"]],[30,0,[\"rowToggleMode\"]],[30,0,[\"wrappedRows\",\"length\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[28,[37,5],null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowToggleMode\",\"rowsCount\",\"row\"],[[30,2,[\"rowValue\"]],[30,2,[\"rowMeta\"]],[30,2,[\"cells\"]],[30,2,[\"rowSelectionMode\"]],[30,2,[\"rowToggleMode\"]],[30,2,[\"rowsCount\"]],[50,\"ember-tr\",0,null,[[\"api\"],[[30,2]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,7],null,[[\"@api\"],[[30,2]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[2]]]]],[1,\"\\n\\n\"]],[1]],[[[1,\"  \"],[18,4,null],[1,\"\\n\"]],[]]]]]],[\"rowValue\",\"api\",\"&default\",\"&else\"],false,[\"vertical-collection\",\"ember-table-private/row-wrapper\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-tr\"]]",
    "moduleName": "ember-table/components/ember-tbody/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});