define("ember-table/components/ember-thead/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.wrappedRows as |api|}}
    {{#if (has-block)}}
      {{yield (hash
        cells=api.cells
        isHeader=api.isHeader
        rowsCount=api.rowsCount
  
        row=(component "ember-tr" api=api)
      )}}
    {{else}}
      <EmberTr @api={{api}} />
    {{/if}}
  {{/each}}
  
  */
  {
    "id": "Vxj6Y/Zb",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"wrappedRows\"]]],null]],null],null,[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[28,[37,5],null,[[\"cells\",\"isHeader\",\"rowsCount\",\"row\"],[[30,1,[\"cells\"]],[30,1,[\"isHeader\"]],[30,1,[\"rowsCount\"]],[50,\"ember-tr\",0,null,[[\"api\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,7],null,[[\"@api\"],[[30,1]]],null],[1,\"\\n\"]],[]]]],[1]],null]],[\"api\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-tr\"]]",
    "moduleName": "ember-table/components/ember-thead/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});