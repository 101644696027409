define("@adopted-ember-addons/ember-stripe-elements/components/stripe-card-cvc", ["exports", "@ember/component", "@adopted-ember-addons/ember-stripe-elements/components/stripe-element", "@ember/template-factory"], function (_exports, _component, _stripeElement, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='ember-stripe-element ember-stripe-card-cvc'>
    {{! template-lint-disable no-invalid-role }}
    <div
      role='mount-point'
      {{did-insert this.registerListeners}}
      {{did-update this.onOptionsChange}}
    ></div>
    {{! template-lint-enable no-invalid-role }}
    {{#if (has-block)}}
      {{yield this.stripeElement this.stripeError}}
    {{/if}}
  </div>
  */
  {
    "id": "JnLL95xd",
    "block": "[[[10,0],[14,0,\"ember-stripe-element ember-stripe-card-cvc\"],[12],[1,\"\\n\"],[1,\"  \"],[11,0],[24,\"role\",\"mount-point\"],[4,[38,0],[[30,0,[\"registerListeners\"]]],null],[4,[38,1],[[30,0,[\"onOptionsChange\"]]],null],[12],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[30,0,[\"stripeElement\"]],[30,0,[\"stripeError\"]]]],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"did-insert\",\"did-update\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/components/stripe-card-cvc.hbs",
    "isStrictMode": false
  });

  class StripeCardCvc extends _stripeElement.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "type", 'cardCvc');
    }

  }

  _exports.default = StripeCardCvc;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StripeCardCvc);
});