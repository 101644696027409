define("ember-table/components/ember-thead/component", ["exports", "@ember/component", "@ember/runloop", "@ember/array", "@ember/debug", "ember-table/-private/utils/default-to", "ember-table/-private/utils/observer", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-table/-private/utils/element", "ember-table/-private/meta-cache", "ember-table/-private/utils/sort", "ember-table/-private/column-tree", "ember-table/components/ember-thead/template"], function (_exports, _component, _runloop, _array, _debug, _defaultTo, _observer, _object, _computed, _utils, _element, _metaCache, _sort, _columnTree, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.setupTHeadForTest = setupTHeadForTest;

  /* global ResizeSensor */
  let isTestingThead = false;

  function setupTHeadForTest(bool) {
    isTestingThead = bool;
  }
  /**
    The table header component. This component manages and receives the column
    definitions for the table, and yields for each row of headers that exists
    (in the case of subcolumns).
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{this.columns}} as |h|>
  
      </t.head>
  
      <t.body @rows={{this.rows}} />
    </EmberTable>
    ```
  
    @yield {object} head - the API object yielded by the table header
    @yield {Component} head.row - The table row component
    @class <EmberThead />
    @public
  */


  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'thead',

    /**
      The API object passed in by the table
       @argument api
      @required
      @type object
    */
    api: null,
    unwrappedApi: (0, _computed.or)('api.api', 'api'),

    /**
      The column definitions for the table
       @argument columns
      @required
      @type array? ([])
    */
    columns: (0, _defaultTo.default)(() => []),

    /**
      Specifies the name of the property on the column objects that should be
      used as the key for caching column metadata. For example, if columns have
      a unique `id` property, the value could be set to `id`. If unspecified,
      the column object itself is used as a key.
       @argument columnKeyPath
      @type string?
    */
    columnKeyPath: null,

    /**
      An ordered array of the sorts applied to the table
      @argument sorts
      @type array? ([])
    */
    sorts: (0, _defaultTo.default)(() => []),

    /**
      An optional sort. If not specified, defaults to `<sortMultiple>`, which
      sorts by each `sort` in `sorts`, in order.
      @argument sortFunction
      @type function? (<sortMultiple>)
    */
    sortFunction: (0, _defaultTo.default)(() => _sort.sortMultiple),

    /**
      An ordered array of the sorts applied to the table
      @argument compareFunction
      @type function? (<compareValues>)
    */
    compareFunction: (0, _defaultTo.default)(() => _sort.compareValues),

    /**
      Flag that allows to sort empty values after non empty ones
      @argument sortEmptyLast
      @type boolean? (false)
    */
    sortEmptyLast: (0, _defaultTo.default)(false),

    /**
      Flag that toggles reordering in the table
      @argument enableReorder
      @type boolean? (true)
    */
    enableReorder: (0, _defaultTo.default)(true),

    /**
      Flag that toggles resizing in the table
      @argument enableResize
      @type boolean? (true)
    */
    enableResize: (0, _defaultTo.default)(true),

    /**
      Enables shadows at the edges of the table to show that the user can scroll
      to view more content. Possible string values are `all`, `horizontal`,
      `vertical`, and `none`. The boolean values `true` and `false` are aliased to `all` and `none`, respectively.
      @argument scrollIndicators
      @type boolean|string? (false)
    */
    scrollIndicators: (0, _defaultTo.default)(false),

    /**
      Sets which column resizing behavior to use. Possible values are `standard`
      (resizing a column pushes or pulls all other columns) and `fluid` (resizing a
      column subtracts width from neighboring columns).
      @argument resizeMode
      @type string? ('standard')
    */
    resizeMode: (0, _defaultTo.default)(_columnTree.RESIZE_MODE.STANDARD),

    /**
      A configuration that controls how columns shrink (or extend) when total column width does not
      match table width. Behavior of column modification is as follows:
       * "equal-column": extra space is distributed equally among all columns
      * "first-column": extra space is added into the first column.
      * "last-column": extra space is added into the last column.
      * "nth-column": extra space is added into the column defined by `fillColumnIndex`.
       @argument fillMode
      @type string? ('equal-column')
    */
    fillMode: (0, _defaultTo.default)(_columnTree.FILL_MODE.EQUAL_COLUMN),

    /**
      Specifies how columns should be sized when the table is initialized. This only affects
      `eq-container-slack` and `gte-container-slack` width constraint modes. Permitted values are
      the same as `fillMode`.
       @argument initialFillMode
      @type string? ('none')
    */
    initialFillMode: (0, _defaultTo.default)(_columnTree.FILL_MODE.NONE),

    /**
      A configuration that controls which column shrinks (or extends) when `fillMode` is
      'nth-column'. This is zero indexed.
       @argument fillColumnIndex
      @type number?
    */
    fillColumnIndex: null,

    /**
      Sets a constraint on the table's size, such that it must be greater than, less
      than, or equal to the size of the containing element.
      Valid values:
        * 'none'
        * 'eq-container'
        * 'gte-container'
        * 'lte-container'
       @argument widthConstraint
      @type string? ('none')
    */
    widthConstraint: (0, _defaultTo.default)(_columnTree.WIDTH_CONSTRAINT.NONE),

    /**
      A numeric adjustment to be applied to the constraint on the table's size.
       @argument containerWidthAdjustment
      @type number?
    */
    containerWidthAdjustment: null,

    /**
      An action that is sent when sorts is updated
      @argument onUpdateSorts
      @type Action?
    */
    onUpdateSorts: null,

    /**
      An action that is sent when columns are reordered
      @argument onReorder
      @type Action?
    */
    onReorder: null,

    /**
      An action that is sent when columns are resized
      @argument onResize
      @type Action?
    */
    onResize: null,
    attributeBindings: ['wrappedRowsCount:data-test-row-count'],
    wrappedRowsCount: (0, _computed.and)('isTesting', 'wrappedRows.length'),

    init() {
      var _this$onReorder, _this$onResize;

      this._super(...arguments);

      if (isTestingThead) {
        this.set('isTesting', true);
      }
      /**
       * A sensor object that sends events to this table component when table size changes. When table
       * is resized, table width & height are updated and other computed properties depending on them
       * also get updated.
       */


      this._tableResizeSensor = null;
      /**
        The map that contains column meta information for this table. Is meant to be
        unique to this table, which is why it is created here.
      */

      let columnKeyPath = this.get('columnKeyPath');
      this.columnMetaCache = new _metaCache.default({
        keyPath: columnKeyPath
      });
      this.columnTree = _columnTree.default.create({
        onReorder: (_this$onReorder = this.onReorder) === null || _this$onReorder === void 0 ? void 0 : _this$onReorder.bind(this),
        onResize: (_this$onResize = this.onResize) === null || _this$onResize === void 0 ? void 0 : _this$onResize.bind(this),
        columnMetaCache: this.columnMetaCache,
        containerWidthAdjustment: this.containerWidthAdjustment
      });
      /**
        The map that contains row meta information for this table header.
      */

      this.rowMetaCache = new Map();

      this._updateApi();

      this._validateUniqueColumnKeys();

      this._updateColumnTree();

      (0, _runloop.scheduleOnce)('actions', this.columnTree, 'performInitialLayout');
      (0, _observer.addObserver)(this, 'scrollIndicators', this._updateApi);
      (0, _observer.addObserver)(this, 'reorderFunction', this._updateApi);
      (0, _observer.addObserver)(this, 'sorts', this._updateApi);
      (0, _observer.addObserver)(this, 'sortFunction', this._updateApi);
      (0, _observer.addObserver)(this, 'sorts', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'columns.[]', this._onColumnsChange);
      (0, _observer.addObserver)(this, 'columnKeyPath', this._updateColumnMetaCache);
      (0, _observer.addObserver)(this, 'fillMode', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'initialFillMode', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'fillColumnIndex', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'resizeMode', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'widthConstraint', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'enableSort', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'enableResize', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'enableReorder', this._updateColumnTree);
    },

    _updateApi() {
      this.set('unwrappedApi.columnTree', this.columnTree);
      this.set('unwrappedApi.compareFunction', this.get('compareFunction'));
      this.set('unwrappedApi.scrollIndicators', this.get('scrollIndicators'));
      this.set('unwrappedApi.sorts', this.get('sorts'));
      this.set('unwrappedApi.sortEmptyLast', this.get('sortEmptyLast'));
      this.set('unwrappedApi.sortFunction', this.get('sortFunction'));
    },

    _updateColumnTree() {
      this.columnTree.set('sorts', this.get('sorts'));
      this.columnTree.set('columns', this.get('columns'));
      this.columnTree.set('fillMode', this.get('fillMode'));
      this.columnTree.set('initialFillMode', this.get('initialFillMode'));
      this.columnTree.set('fillColumnIndex', this.get('fillColumnIndex'));
      this.columnTree.set('resizeMode', this.get('resizeMode'));
      this.columnTree.set('widthConstraint', this.get('widthConstraint'));
      this.columnTree.set('enableSort', this.get('enableSort'));
      this.columnTree.set('enableResize', this.get('enableResize'));
      this.columnTree.set('enableReorder', this.get('enableReorder'));
    },

    _updateColumnMetaCache() {
      this._validateUniqueColumnKeys();

      this.columnMetaCache.keyPath = this.get('columnKeyPath');
    },

    _onColumnsChange() {
      if (this.get('columns.length') === 0) {
        return;
      }

      this._validateUniqueColumnKeys();

      this._updateColumnTree();

      (0, _runloop.scheduleOnce)('actions', this, this.fillupHandler);
    },

    _validateUniqueColumnKeys() {
      let columns = this.get('columns');
      let columnKeyPath = this.get('columnKeyPath');

      if (columns && columnKeyPath) {
        // traverse tree to collect column keys
        let keys = [];
        let queue = [...columns];

        while (queue.length > 0) {
          let column = queue.shift();
          keys.push((0, _object.get)(column, columnKeyPath));

          if (column.subcolumns) {
            queue.push(...column.subcolumns);
          }
        }

        let presentKeys = (0, _array.A)(keys.filter(_utils.isPresent)); // If a column has a falsey key, its meta data cannot be mapped to a
        // replacement column in the future. This is not necessarily a problem,
        // but it's reasonable to assume the consumer will want to avoid this
        // scenario if they have bothered to set `columnKeyPath` at all.

        (false && !(presentKeys.length === keys.length) && (0, _debug.assert)('if columnKeyPath is specified, every column must have a key', presentKeys.length === keys.length)); // Duplicate non-blank keys are the real problem; the meta cache will map
        // two columns to the same meta object and havoc will ensue.

        (false && !(presentKeys.uniq().length === presentKeys.length) && (0, _debug.assert)('if columnKeyPath is specified, no two columns can share the same key', presentKeys.uniq().length === presentKeys.length));
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._container = (0, _element.closest)(this.element, '.ember-table-overflow');
      this.columnTree.registerContainer(this._container);
      this._tableResizeSensor = new ResizeSensor(this._container, (0, _runloop.bind)(this, this.fillupHandler));
    },

    willDestroyElement() {
      this._tableResizeSensor.detach(this._container);

      this.columnTree.destroy(); // Clean the column meta cache

      for (let [column, meta] of this.columnMetaCache.entries()) {
        meta.destroy();
        this.columnMetaCache.delete(column);
      } // Clean the row meta cache


      for (let [row, meta] of this.rowMetaCache.entries()) {
        meta.destroy();
        this.rowMetaCache.delete(row);
      }

      this._super(...arguments);
    },

    enableSort: (0, _computed.notEmpty)('onUpdateSorts'),
    wrappedRows: (0, _object.computed)('columnTree.rows.[]', 'sorts.[]', 'headerActions.[]', 'fillMode', 'fillColumnIndex', function () {
      let rows = this.get('columnTree.rows');
      let sorts = this.get('sorts');
      let columnMetaCache = this.get('columnMetaCache');
      let rowMetaCache = this.get('rowMetaCache');
      return (0, _array.A)(rows.map((row, i) => {
        let rowMeta = rowMetaCache.get(row);

        if (!rowMeta) {
          rowMeta = _object.default.create();
          rowMetaCache.set(row, rowMeta);
        }

        rowMeta.set('index', i);
        let cells = (0, _array.A)(row.map(columnValue => {
          let columnMeta = columnMetaCache.get(columnValue);
          return {
            columnValue,
            columnMeta,
            rowMeta,
            sorts,
            sendUpdateSort: this.sendUpdateSort.bind(this)
          };
        }));
        return {
          cells,
          rowMeta,
          rowsCount: rows.length,
          isHeader: true
        };
      }));
    }),

    sendUpdateSort(newSorts) {
      var _this$onUpdateSorts;

      (_this$onUpdateSorts = this.onUpdateSorts) === null || _this$onUpdateSorts === void 0 ? void 0 : _this$onUpdateSorts.call(this, newSorts);
    },

    fillupHandler() {
      if (this.isDestroying) {
        return;
      }

      this.get('columnTree').ensureWidthConstraint();
    }

  });

  _exports.default = _default;
});