define("ember-select/templates/components/select-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "J9JUaheZ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"options\"]]],null]],null],null,[[[41,[30,1,[\"isVisible\"]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"model\",\"select\",\"hover\"],[[30,1],[28,[37,4],[[30,0],\"select\"],null],[28,[37,4],[[30,0],\"hover\"],null]]]]],[1,\"\\n\"]],[]],null]],[1]],null]],[\"option\"],false,[\"each\",\"-track-array\",\"if\",\"select-dropdown-option\",\"action\"]]",
    "moduleName": "ember-select/templates/components/select-dropdown.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});