define("ember-cli-notifications/templates/components/notification-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ember-cli-notifications-notification__container {{this.positionClass}}"
    style={{this.inlineStyle}}
    data-test-notification-container={{this.position}}
  >
    {{#each this.notifications.content as |notification|}}
      <NotificationMessage @notification={{notification}} />
    {{/each}}
  </div>
  */
  {
    "id": "QCMO83vx",
    "block": "[[[10,0],[15,0,[29,[\"ember-cli-notifications-notification__container \",[30,0,[\"positionClass\"]]]]],[15,5,[30,0,[\"inlineStyle\"]]],[15,\"data-test-notification-container\",[30,0,[\"position\"]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"notifications\",\"content\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@notification\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"notification\"],false,[\"each\",\"-track-array\",\"notification-message\"]]",
    "moduleName": "ember-cli-notifications/templates/components/notification-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});