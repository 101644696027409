define("ember-user-activity/services/user-activity", ["exports", "ember-user-activity/services/-private/fastboot-aware-event-manager", "ember", "@ember/array", "@ember/runloop", "@ember/service", "@ember/utils", "ember-user-activity/utils/storage-available"], function (_exports, _fastbootAwareEventManager, _ember, _array, _runloop, _service, _utils, _storageAvailable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserActivityService = (_dec = (0, _service.inject)('ember-user-activity@scroll-activity'), (_class = class UserActivityService extends _fastbootAwareEventManager.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "scrollActivity", _descriptor, this);

      _defineProperty(this, "EVENT_THROTTLE", 100);

      _defineProperty(this, "defaultEvents", ['keydown', 'mousedown', 'scroll', 'touchstart', 'storage']);

      _defineProperty(this, "enabledEvents", null);

      _defineProperty(this, "_eventsListened", null);

      _defineProperty(this, "_eventSubscriberCount", null);

      _defineProperty(this, "_throttledEventHandlers", null);

      _defineProperty(this, "_boundEventHandler", null);

      _defineProperty(this, "localStorageKey", 'ember-user-activity');
    }

    init() {
      super.init(...arguments);

      if (_ember.default.testing) {
        // Do not throttle in testing mode
        this.set('EVENT_THROTTLE', 0);
      }

      this._boundEventHandler = this.handleEvent.bind(this);
      this._eventsListened = (0, _array.A)();
      this._eventSubscriberCount = {};
      this._throttledEventHandlers = {};

      if ((0, _utils.isEmpty)(this.enabledEvents)) {
        this.set('enabledEvents', (0, _array.A)());
      }

      this._setupListeners();
    }

    on(eventName) {
      this.enableEvent(eventName);

      if (this._eventSubscriberCount[eventName]) {
        this._eventSubscriberCount[eventName]++;
      } else {
        this._eventSubscriberCount[eventName] = 1;
      }

      return super.on(...arguments);
    }

    off(eventName) {
      if (this._eventSubscriberCount[eventName]) {
        this._eventSubscriberCount[eventName]--;
      } else {
        delete this._eventSubscriberCount[eventName];
      }

      return super.off(...arguments);
    }

    has(eventName) {
      return this._eventSubscriberCount[eventName] && this._eventSubscriberCount[eventName] > 0;
    }

    handleEvent(event) {
      if (event.type === 'storage' && event.key !== this.localStorageKey) {
        return;
      }

      (0, _runloop.throttle)(this, this._throttledEventHandlers[event.type], event, this.EVENT_THROTTLE);
    }

    _handleScroll() {
      this.handleEvent({
        type: 'scroll'
      });
    }

    _setupListeners() {
      this.defaultEvents.forEach(eventName => {
        this.enableEvent(eventName);
      });
    }

    _listen(eventName) {
      if (eventName === 'scroll') {
        this.scrollActivity.on('scroll', this, this._handleScroll);
      } else if (this._eventsListened.indexOf(eventName) === -1) {
        if (this._isFastBoot) {
          return;
        }

        this._eventsListened.pushObject(eventName);

        window.addEventListener(eventName, this._boundEventHandler, true);
      }
    }

    enableEvent(eventName) {
      if (!this.isEnabled(eventName)) {
        this.enabledEvents.pushObject(eventName);

        this._throttledEventHandlers[eventName] = function fireEnabledEvent(event) {
          if (this.isEnabled(event.type)) {
            this.fireEvent(event);
          }
        };

        this._listen(eventName);
      }
    }

    disableEvent(eventName) {
      this.enabledEvents.removeObject(eventName);

      this._eventsListened.removeObject(eventName);

      this._throttledEventHandlers[eventName] = null;

      if (eventName === 'scroll') {
        this.scrollActivity.off('scroll', this, this._handleScroll);
      } else {
        if (this._isFastBoot) {
          return;
        }

        window.removeEventListener(eventName, this._boundEventHandler, true);
      }
    }

    fireEvent(event) {
      // Only fire events that have subscribers
      if (this.has(event.type)) {
        this.trigger(event.type, event);
      }

      if (this.has('userActive')) {
        this.trigger('userActive', event);
      }

      if (this._eventsListened.includes('storage') && (0, _storageAvailable.default)('localStorage')) {
        // We store a date here since we have to update the storage with a new value
        localStorage.setItem(this.localStorageKey, new Date());
      }
    }

    isEnabled(eventName) {
      return this.enabledEvents.includes(eventName);
    }

    willDestroy() {
      while (this._eventsListened.length > 0) {
        this.disableEvent(this._eventsListened[0]);
      }

      this._eventsListened = (0, _array.A)();
      this._eventSubscriberCount = {};
      this._throttledEventHandlers = {};

      if (this.localStorageKey && (0, _storageAvailable.default)('localStorage')) {
        localStorage.removeItem(this.localStorageKey);
      }

      super.willDestroy(...arguments);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "scrollActivity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserActivityService;
});