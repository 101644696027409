define("ember-component-and-route-css/utils/init-route-styles", ["exports", "ember-component-and-route-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initRouteStyles;

  function initRouteStyles(owner, routeNames) {
    const classes = [];

    for (let i = 0; i < routeNames.length; i++) {
      const routeName = routeNames[i];
      const currentPath = routeName.replace(/\./g, '/');
      const styleNamespace = _podNames.default[`styles/${currentPath}`];

      if (styleNamespace) {
        classes.push(styleNamespace);
        const controller = owner.lookup(`controller:${routeName}`);

        if (controller) {
          controller.set('styleNamespace', styleNamespace);
        }
      }
    }

    let controller = owner.lookup('controller:application');

    if (controller) {
      controller.set('routeStyleNamespaceClassSet', classes.join(' '));
    }
  }
});