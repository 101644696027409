define("ember-modal-dialog/templates/components/basic-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberWormhole @to={{this.destinationElementId}}>
    {{#if this.isOverlaySibling}}
      <div class="{{this.wrapperClassNamesString}} {{this.wrapperClass}}">
        {{#if this.hasOverlay}}
          <div
            class={{this.overlayClassNamesString}}
            onclick={{action this.onClickOverlay}}
            tabindex="-1"
            data-emd-overlay
          >
          </div>
        {{/if}}
        <EmberModalDialogPositionedContainer @class={{this.containerClassNamesString}} @targetAttachment={{this.targetAttachment}} @target={{this.legacyTarget}}>
          {{yield}}
        </EmberModalDialogPositionedContainer>
      </div>
    {{else}}
      <div class="{{this.wrapperClassNamesString}} {{this.wrapperClass}}">
        {{#if this.hasOverlay}}
          <div
            class={{this.overlayClassNamesString}}
            onclick={{action (ignore-children this.onClickOverlay)}}
            tabindex="-1"
            data-emd-overlay
          >
            <EmberModalDialogPositionedContainer @class={{this.containerClassNamesString}} @targetAttachment={{this.targetAttachment}} @target={{this.legacyTarget}}>
              {{yield}}
            </EmberModalDialogPositionedContainer>
          </div>
        {{else}}
          <EmberModalDialogPositionedContainer @class={{this.containerClassNamesString}} @targetAttachment={{this.targetAttachment}} @target={{this.legacyTarget}}>
            {{yield}}
          </EmberModalDialogPositionedContainer>
        {{/if}}
      </div>
    {{/if}}
  </EmberWormhole>
  
  */
  {
    "id": "wT0PngaM",
    "block": "[[[8,[39,0],null,[[\"@to\"],[[30,0,[\"destinationElementId\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isOverlaySibling\"]],[[[1,\"    \"],[10,0],[15,0,[29,[[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"        \"],[10,0],[15,0,[30,0,[\"overlayClassNamesString\"]]],[15,\"onclick\",[28,[37,2],[[30,0],[30,0,[\"onClickOverlay\"]]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,3],null,[[\"@class\",\"@targetAttachment\",\"@target\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"legacyTarget\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[18,1,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"        \"],[10,0],[15,0,[30,0,[\"overlayClassNamesString\"]]],[15,\"onclick\",[28,[37,2],[[30,0],[28,[37,5],[[30,0,[\"onClickOverlay\"]]],null]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@class\",\"@targetAttachment\",\"@target\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"legacyTarget\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[18,1,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],null,[[\"@class\",\"@targetAttachment\",\"@target\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"legacyTarget\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[18,1,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"ember-wormhole\",\"if\",\"action\",\"ember-modal-dialog-positioned-container\",\"yield\",\"ignore-children\"]]",
    "moduleName": "ember-modal-dialog/templates/components/basic-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});